/** @jsxImportSource @emotion/react */

import { useAppContext } from '@/contexts';
import {
  TLocales,
  addLocaleToPath,
  localeEN,
  localeNL,
  validateLocale,
} from '@/utils/locale';
import { Button } from '@sal-solution/ui-web';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC } from 'react';
import tw from 'twin.macro';
import { columStyle } from '../Section/Section';
import enFlag from './assets/en.png';
import nlFlag from './assets/nl.png';

const Nav = tw.nav`items-center sticky top-0 z-20 lg:(col-start-1 col-end-13 w-full h-[8vh] grid grid-cols-12)`;
const NavContent = tw.div`flex items-center justify-between w-full `;
const NavLinks = tw.div`flex items-center gap-4 px-4 rounded-full shadow-md`;
const FlagImage = tw(Image)`w-6 h-full`;

const NAVIGATION_STYLE_VARIANTS = {
  DEFAULT: tw`bg-white text-black px-4 py-2 rounded-full h-full`,
  DARK: tw`bg-black text-white px-4 py-2 rounded-full h-full`,
  TRANSPARENT: tw`bg-transparent text-white px-4 py-2 h-full`,
};

type StyleProps = {
  variant?: keyof typeof NAVIGATION_STYLE_VARIANTS;
};

const Locales: FC<StyleProps> = ({ variant = 'DEFAULT' }) => {
  const selectedStyle = NAVIGATION_STYLE_VARIANTS[variant];
  const pathname = usePathname();
  const changeLocale = (locale: TLocales) => {
    document.cookie = `NEXT_LOCALE=${locale}; path=/`;
    const path = addLocaleToPath(pathname, locale);
    window.location.href = path;
  };
  return (
    <div
      css={[selectedStyle]}
      tw="flex items-center gap-4 mr-auto px-4 py-2 rounded-full h-full shadow-md"
    >
      <FlagImage
        src={nlFlag}
        alt="flag-nl"
        width={24}
        height={24}
        onClick={() => changeLocale(localeNL)}
      />

      <FlagImage
        src={enFlag}
        alt="flag-en"
        width={24}
        height={24}
        onClick={() => changeLocale(localeEN)}
      />
    </div>
  );
};

export const Navigation: FC<StyleProps> = ({
  variant = 'DEFAULT',
  ...props
}) => {
  const { isSignedIn } = useAppContext();
  const pathname = usePathname();
  const localeInPath = validateLocale(pathname.split('/')[1]);
  const selectedStyle = NAVIGATION_STYLE_VARIANTS[variant];
  const navItems = isSignedIn ? authenticatedNavItems : unAuthenticatedNavItems;
  const changeLocale = (locale: TLocales) => {
    document.cookie = `NEXT_LOCALE=${locale}; path=/`;
    const path = addLocaleToPath(pathname, locale);
    window.location.href = path;
  };
  return (
    <Nav {...props}>
      <NavContent css={[columStyle, tw`hidden lg:flex`]}>
        <Link href="/" locale={localeInPath}>
          <Image
            src={'/assets/icons/house-icon.svg'}
            width={32}
            height={32}
            alt="Housing"
            tw="mr-4"
          />
        </Link>
        <Locales variant={variant} />
        <NavLinks css={[selectedStyle]}>
          {navItems.map(({ href, localeLables }, i) => (
            <Button
              tw="py-0 no-underline min-h-0 h-4"
              variant="link"
              key={i}
              href={
                localeInPath === localeNL
                  ? href
                  : addLocaleToPath(href, localeInPath)
              }
              as={Link}
              // locale={localeInPath}
            >
              {localeLables[localeInPath]}
            </Button>
          ))}
        </NavLinks>
      </NavContent>
      <NavContent css={[columStyle, tw`flex lg:hidden`]}>
        <div className="navbar bg-base-100">
          <div className="navbar-start">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost btn-circle"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h7"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
              >
                {navItems.map(({ href, localeLables }, i) => (
                  <li key={i}>
                    <Link key={i} href={href}>
                      {localeLables[localeInPath]}
                    </Link>
                  </li>
                ))}
                <li onClick={() => changeLocale(localeNL)}>
                  <div tw="flex w-full">
                    <FlagImage
                      src={nlFlag}
                      alt="flag-nl"
                      width={24}
                      height={24}
                      tw="h-4"
                    />{' '}
                    NL
                  </div>
                </li>
                <li onClick={() => changeLocale(localeEN)}>
                  <div tw="flex w-full">
                    <FlagImage
                      src={enFlag}
                      alt="flag-en"
                      width={24}
                      height={24}
                      tw="h-4"
                    />{' '}
                    EN
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="navbar-end">
            <Link href="/" locale={localeInPath}>
              <Image
                src={'/assets/icons/icon.png'}
                width={32}
                height={32}
                alt="Housing"
                tw="mr-4"
              />
            </Link>
          </div>
        </div>
      </NavContent>
    </Nav>
  );
};

const unAuthenticatedNavItems = [
  {
    label: 'Home',
    href: '/',
    localeLables: {
      [localeEN]: 'Home',
      [localeNL]: 'Home',
    },
  },
  {
    label: 'Contact',
    href: '/contact',
    localeLables: {
      [localeEN]: 'Contact',
      [localeNL]: 'Contact',
    },
  },
  {
    label: 'Sign Up',
    href: '/sign-up',
    localeLables: {
      [localeEN]: 'Sign Up',
      [localeNL]: 'Registreren',
    },
  },
  {
    label: 'Sign In',
    href: '/sign-in',
    localeLables: {
      [localeEN]: 'Sign In',
      [localeNL]: 'Inloggen',
    },
  },
];

const authenticatedNavItems = [
  {
    label: 'Home',
    href: '/',
    localeLables: { [localeEN]: 'Home', [localeNL]: 'Home' },
  },
  {
    label: 'Contact',
    href: '/contact',
    localeLables: {
      [localeEN]: 'Contact',
      [localeNL]: 'Contact',
    },
  },
  {
    label: 'Profile',
    href: '/profile',
    localeLables: {
      [localeEN]: 'Profile',
      [localeNL]: 'Profiel',
    },
  },
];
