/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import tw from 'twin.macro';
import { SizeIcon, DoorIcon } from './assets/Icons';
import { HousesQuery } from './houses.generated';
import appartmentImage from './assets/appartment.png';
import { Text } from '@sal-solution/ui-web';
import Image from 'next/image';
import { toCurrency } from '@sal-solution/utils';
import housePlaceholder from './assets/house-placeholder.png';
export type HouseCardProps = {
  house?: NonNullable<HousesQuery['latestHouses']>[0];
  hideSurface?: boolean;
  hideRooms?: boolean;
};

const placeholderImageUrl =
  'data:image/svg+xml;utf8,%3Csvg%20width%3D%22600%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20fill%3D%22%23FBFBFB%22%20d%3D%22M0%200h600v400H0z%22%2F%3E%3Cpath%20fill%3D%22%23888%22%20fill-rule%3D%22nonzero%22%20d%3D%22M283.2%20277v-54.353h33.6V277h42v-72.47H384L300%20123l-84%2081.53h25.2V277z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E';

export const HouseCard: FC<HouseCardProps> = ({
  house,
  hideRooms,
  hideSurface,
}) => {
  const hasImage = house?.image && house?.image !== placeholderImageUrl;
  const hideSurfaceAndRooms = hideRooms && hideSurface;

  return (
    <div tw="card indicator w-full">
      <span tw="indicator-item badge badge-primary text-white right-4 -top-2 absolute">
        new
      </span>

      <figure>
        <img
          src={hasImage && house?.image ? house?.image : housePlaceholder.src}
          alt={house?.title || 'House'}
          css={[
            tw`rounded-xl shadow-xl w-full h-40 `,
            hasImage ? tw`object-cover` : tw`p-8 object-contain`,
          ]}
        />
      </figure>
      <div tw="items-center text-left mt-4">
        <Text as="h2" tw="font-bold">
          {house?.title}
        </Text>
        <Text tw="text-gray-500">{house?.city}</Text>
      </div>
      <div tw="flex justify-between">
        <div>{house?.price && <Text>{toCurrency(house?.price)} p/m</Text>}</div>
        {!hideSurfaceAndRooms && (
          <div tw="flex gap-4">
            {!hideSurface && (
              <Text tw="flex gap-1">
                <SizeIcon tw="w-4" />
                {house?.surface} m²
              </Text>
            )}
            {!hideRooms && (
              <Text tw="flex gap-1">
                <DoorIcon tw="w-4" />
                {house?.rooms || house?.bedrooms}
              </Text>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
