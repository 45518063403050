import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HousesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HousesQuery = { __typename?: 'Query', latestHouses: Array<{ __typename?: 'house', postcode?: string | null, price?: number | null, rooms?: number | null, search?: string | null, street?: string | null, surface?: number | null, title?: string | null, image?: string | null, city?: string | null, bedrooms?: number | null }> };


export const HousesDocument = gql`
    query houses {
  latestHouses {
    postcode
    price
    rooms
    search
    street
    surface
    title
    image
    city
    bedrooms
  }
}
    `;

/**
 * __useHousesQuery__
 *
 * To run a query within a React component, call `useHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHousesQuery(baseOptions?: Apollo.QueryHookOptions<HousesQuery, HousesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HousesQuery, HousesQueryVariables>(HousesDocument, options);
      }
export function useHousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HousesQuery, HousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HousesQuery, HousesQueryVariables>(HousesDocument, options);
        }
export function useHousesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HousesQuery, HousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HousesQuery, HousesQueryVariables>(HousesDocument, options);
        }
export type HousesQueryHookResult = ReturnType<typeof useHousesQuery>;
export type HousesLazyQueryHookResult = ReturnType<typeof useHousesLazyQuery>;
export type HousesSuspenseQueryHookResult = ReturnType<typeof useHousesSuspenseQuery>;
export type HousesQueryResult = Apollo.QueryResult<HousesQuery, HousesQueryVariables>;