/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import 'twin.macro';

export type ToggleProps = {
  text: string;
  name: string;
  defaultChecked?: boolean;
  disabled?: boolean;
};

export const Toggle: FC<ToggleProps> = ({
  text,
  name,
  defaultChecked,
  disabled,
}) => {
  return (
    <div tw="form-control">
      <label tw="label cursor-pointer gap-4">
        <span tw="label-text">{text}</span>
        <input
          name={name}
          type="checkbox"
          tw="toggle toggle-success"
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
      </label>
    </div>
  );
};
