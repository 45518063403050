/** @jsxImportSource @emotion/react */

import { useLocale } from '@/hooks/useLocal';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import tw from 'twin.macro';
import packageJson from '@/package.json';
import { localeEN, localeNL } from '@/utils/locale';

const Foot = tw.footer``;

export const Footer: FC = () => {
  const locale = useLocale(FooterLocale);
  return (
    <Foot tw="footer px-10 py-10 bg-base-200 text-base-content lg:(grid grid-cols-12 px-0)">
      <aside tw="lg:(col-start-3 col-end-6)">
        <Image
          width="50"
          height="50"
          src="/assets/icons/icon.png"
          alt="Housing Logo"
        />
        <p>
          Rent watcher
          {/* <br />
          {locale.since} */}
          <br />
          {packageJson.version}
        </p>
      </aside>
      <nav tw="lg:(col-start-6 col-end-8)">
        <h6 tw="footer-title">{locale.services}</h6>
        <Link href="/sign-up" tw="link link-hover">
          {locale.subscribe}
        </Link>
      </nav>
      <nav tw="lg:(col-start-8 col-end-10)">
        <h6 tw="footer-title">{locale.company}</h6>
        <Link href="/contact" tw="link link-hover">
          {locale.contact}
        </Link>
      </nav>
      <nav tw="lg:(col-start-10 col-end-12)">
        <h6 tw="footer-title">{locale.legal}</h6>
        <Link href="/terms" tw="link link-hover">
          {locale.terms}
        </Link>
        <Link href="/privacy" tw="link link-hover">
          {locale.privacy}
        </Link>
      </nav>
    </Foot>
  );
};

const FooterLocale = {
  [localeEN]: {
    since: 'Providing reliable tech since 2024',
    services: 'Services',
    company: 'Company',
    legal: 'Legal',
    subscribe: 'Subscribe!',
    contact: 'Contact',
    terms: 'Terms of use',
    privacy: 'Privacy policy',
  },
  [localeNL]: {
    since: 'Betrouwbare technologie sinds 2024',
    services: 'Diensten',
    company: 'Bedrijf',
    legal: 'Juridisch',
    subscribe: 'Abonneren!',
    contact: 'Contact',
    terms: 'Gebruiksvoorwaarden',
    privacy: 'Privacybeleid',
  },
};
