/** @jsxImportSource @emotion/react */

import { sendGTMEvent } from '@next/third-parties/google';
import { Text } from '@sal-solution/ui-web';
import Link from 'next/link';
import 'twin.macro';
import { HouseCard } from './HouseCard';
import { useHousesQuery } from './houses.generated';

export const Houses = () => {
  const { data, loading, error } = useHousesQuery();
  return (
    <div tw="grid lg:(grid-cols-4 gap-4) space-y-8 lg:space-y-0">
      {loading && (
        <Text tw="text-center w-full lg:(col-start-1 col-end-5)">
          <span tw="loading loading-spinner loading-xs"></span>
        </Text>
      )}
      {error && <Text tw="text-center w-full">Error: {error.message}</Text>}
      {data?.latestHouses?.map((house, index) => {
        return (
          <Link
            href="/sign-up"
            key={index}
            onClick={() =>
              sendGTMEvent({
                event: 'click',
                category: 'house',
                action: 'click',
              })
            }
          >
            <HouseCard house={house} hideRooms hideSurface />
          </Link>
        );
      })}
    </div>
  );
};
