/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { Review, Slider } from '@sal-solution/ui-web';
import { FC } from 'react';

export const Reviews: FC = () => {
  return (
    <Slider
      tw="h-full"
      slides={reviews.map((item, i) => (
        <Review key={i} {...item} />
      ))}
    />
  );
};

const reviews = [
  {
    person: 'O',
    rating: 4,
    quote:
      ' Super blij met Rentwatcher! Binnen enkele weken een appartement gevonden in Amsterdam dankzij alle meldingen via Whatsapp. Aanrader!',
  },
  {
    person: 'D',
    rating: 5,
    quote:
      ' Rentwatcher heeft me geholpen snel een woning in Rotterdam te vinden. Die realtime meldingen zijn echt heel handig, thanks!',
  },
  {
    person: 'E',
    rating: 5,
    quote:
      'Rentwatcher made my apartment search so much easier! I received real-time alerts that matched my criteria perfectly.',
  },
  {
    person: 'S',
    rating: 5,
    quote:
      "Rentwatcher is a game-changer! I got immediate updates on new listings and found an ideal apartment in Tilburg quickly. Plus, it's cheaper than other services..",
  },
];
