/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import tw from 'twin.macro';
import { Footer } from './Footer/Footer';
import { Navigation } from './Navigation/Navigation';

const Main = tw.main`grid min-h-screen w-full lg:grid-cols-12`;

type PageLayoutProps = {
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  sideContent?: React.ReactNode;
  navContent?: React.ReactNode;
  children: React.ReactNode;
};

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  headerContent,
  navContent = <Navigation />,
  footerContent = <Footer />,
  sideContent,
  ...rest
}) => {
  if (navContent)
    return (
      <>
        {navContent}
        <Main {...rest}>
          {sideContent && sideContent}
          {children}
        </Main>
        {footerContent}
      </>
    );
  return (
    <>
      <Main {...rest}>
        {sideContent && sideContent}
        {children}
      </Main>
      {footerContent}
    </>
  );
};
